
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getUserdynamic, getUserInfo } from '@/api/user'
import { timeFormat } from '@/utils/util'
import { api } from '@/api/useAxios'
import { changeShareInfo } from '@/utils/wechat'
import { ImagePreview } from 'vant';


export default {
  name: 'teacherHomePage',
  setup: () => {
    const router = useRouter()
    const { userId } = useRoute().params
    const dataList = [
      {
        id: 1,
        name: '关注',
        value: 0
      },
      {
        id: 2,
        name: '粉丝',
        value: 0
      },
      {
        id: 3,
        name: '动态',
        value: 0
      }
    ]
    const dynamicList = ref<any>([])
    const userInfo = ref({})
    const courseList = ref([])
    const qryUserdynamic = (params: any) => {
      getUserdynamic(params).then((r: any) => {
        dynamicList.value = r.records
        dataList[2].value = r.total
      })
    }
    const qryUserInfo = (params: any) => {
      getUserInfo(userId).then((r: any) => {
        userInfo.value = r
        courseList.value = r.courseList.slice(0, 3)
        dataList[0].value = r.followingCnt ? r.followingCnt : 0
        dataList[1].value = r.fansCnt ? r.fansCnt : 0
        changeShareInfo({
          title: r.username,
          desc: r.position,
          imgUrl: r.avatar,
          link: window.location.href
        })

      })
    }
    qryUserInfo(1)
    qryUserdynamic({
      userId: userId,
      current: 1,
      size: 10
    })
    const handleAttention = (id: number) => {
      api.post('/focus/following/' + id).then(() => {
         qryUserInfo(1)
      })
    }
    const preview = (src: string) => {
      ImagePreview({
        images: [src],
        closeable: true
      });
    }
    return {
      userId,
      handleAttention,
      dataList,
      userInfo,
      courseList,
      dynamicList,
      timeFormat,
      goBack: () => {
        router.go(-1)
      },
      preview
    }
  }
}
