const timeFormat = (stringTime: string) => {
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const month = day * 30
  const time1 = new Date().getTime()// 当前的时间戳
  const time2 = new Date(stringTime.replace(/\-/g, '/')).getTime()// 指定时间的时间戳
  const time = time1 - time2
  let result = ''
  if (time < 0) {
    result = '刚刚发布！'
  } else if (time / month >= 1) {
    result = (time / month).toFixed() + '个月前'
  } else if (time / week >= 1) {
    result = (time / week).toFixed() + '周前'
  } else if (time / day >= 1) {
    result = (time / day).toFixed() + '天前'
  } else if (time / hour >= 1) {
    result = (time / hour).toFixed() + '小时前'
  } else if (time / minute >= 1) {
    result = (time / minute).toFixed() + '分钟前'
  } else {
    result = '刚刚发布！'
  }
  return result
}

export {
  timeFormat
}
